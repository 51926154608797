/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
import {
  Button,
  Header,
  IconButton,
  Steps,
  Typography,
} from '@wisecare-tech/design-system-web';
import React, { ReactNode, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useTopicConfig } from '~/presentation/hooks/sac/createTopicConfig';
import { CreateTopicConfigSchema } from '~/validation/sac/createTopConfig';
import { Container, SubHeader } from '../Sac/styles';
import { CreateTopicConfigStepOne } from './StepOne';
import { AttendantsStepTwo } from './StepTwo';
import { Footer, FormButtonContainer, SacForm, StepContainer } from './styles';

export const CreateSacTopic: React.FC = () => {
  const { loading, submitValues, handleBackStep } = useTopicConfig();

  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useFormContext<CreateTopicConfigSchema>();

  const history = useHistory();

  const step = watch('step');

  const steps = useMemo(
    () => [
      {
        active: step === 1,
        completed: step > 1,
        label: 'Configurações gerais',
      },
      {
        active: step === 2,
        completed: step > 2,
        label: 'Atendentes',
      },
    ],
    [step],
  );

  const renderForm: Record<number, ReactNode> = {
    1: <CreateTopicConfigStepOne />,
    2: <AttendantsStepTwo />,
  };

  if (loading) return <div>loading...</div>;

  return (
    <Container>
      <div style={{ position: 'sticky', top: 0 }}>
        <Header
          onLogout={() => console.log('logout')}
          options={[
            {
              title: 'Administração',
              href: '/sac',
            },
          ]}
        />
        <SubHeader>
          <IconButton
            id="button-back"
            variant="primary"
            icon="arrow_back"
            size="md"
          />

          <Typography id="subheader_title" variant="b2_14semibold">
            AtendeFácil
          </Typography>
        </SubHeader>
      </div>
      <div style={{ height: '100%' }}>
        <SacForm
          onSubmit={handleSubmit(submitValues, data => console.log(data))}
        >
          <StepContainer>
            <Steps steps={steps} />
          </StepContainer>
          {renderForm[step]}
          <Footer>
            <Button
              type="button"
              variant="outlinedDestructive"
              size="lg"
              onClick={() => history.push('/sac')}
            >
              Cancelar
            </Button>
            <FormButtonContainer>
              {step > 1 && (
                <Button
                  type="button"
                  variant="outlined"
                  size="lg"
                  onClick={handleBackStep}
                >
                  Voltar
                </Button>
              )}
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={!isValid}
              >
                Avançar
              </Button>
            </FormButtonContainer>
          </Footer>
        </SacForm>
      </div>
    </Container>
  );
};
