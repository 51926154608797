import {
  Button,
  IconButton,
  Typography,
} from '@wisecare-tech/design-system-web';
import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import React from 'react';
import { closeModal } from '~/utils/closeModal';
import {
  Code,
  Container,
  ContainerModal,
  Footer,
  Header,
  Information,
  Pre,
  SubTitle,
  Title,
} from './styles/StyledIncorporationCode';

export interface propsIncorporationCode {
  message: iMessage;
}

export const IncorporationCode: React.FC<propsIncorporationCode> = ({
  message,
}) => {
  const msgName = MessageOptions.incorporationCode;

  const { active, actionOk, actionCancel, data } = message;

  return (
    <>
      {msgName === active ? (
        <ContainerModal>
          <Container>
            <Header>
              <Information>
                <Title>Código de incorporação</Title>
                <SubTitle>
                  Copie e cole o código de incorporação abaixo no HTML do site
                  da organização.
                </SubTitle>
              </Information>
              <IconButton
                variant="primary"
                icon="close"
                size="sm"
                onClick={closeModal}
              />
            </Header>
            <Code>
              <Pre>
                <Typography variant="b4_14regular">{data?.code}</Typography>
              </Pre>
            </Code>
            <Footer>
              <Button variant="secondary" onClick={closeModal}>
                Cancelar
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  actionOk?.();
                  closeModal();
                }}
              >
                Copiar código
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      ) : null}
    </>
  );
};
