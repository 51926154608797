import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress } from 'material-ui';
import {
  IconMicrophoneOn,
  IconMicrophoneOff,
  IconCameraOn,
  IconCameraOff,
  IconCheck,
} from 'presentation/base/icons';
import WaitingRoomCard, {
  iWaitingRoomCardProps,
} from 'presentation/components/waitingRoomCard';
import { AlertMessage } from 'presentation/components/messages/AlertMessage';
import { useLocation } from 'react-router';
import SacWaitingRoomInfoCard from 'presentation/components/sacWaitingRoomInfoCard';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'App';
import { Button } from 'presentation/components/UI';
import { makeRemoteListSacRequestById } from '~/main/factories/usecases/sacRequest/ListSacRequestByIdFactory';
import { makeRemoteGetPublicTopicById } from '~/main/factories/usecases/sacTopic/GetPublicTopicsByIdFactory';
import { GetPublicTopicById } from 'domain/usecases/sacTopic/remote';
import { toast } from 'react-toastify';
import {
  Body,
  Container,
  Left,
  Right,
  Header,
  WaitingRoomContentTabs,
  WaitingRoomContentHeader,
  WaitingRoomContentBody,
  WaitingRoomContentFooter,
  WaitingRoomContentFooterButton,
  WaitingRoomContentFooterButtons,
  WaitingRoomContentStyle,
  FinishedContainer,
  Finished,
} from './styles/StyledSacWaitingRoom';

const SacWaitingRoom: React.FC = () => {
  const [isMicOn, setIsMicOn] = useState<boolean>(true);
  const [isCamOn, setIsCamOn] = useState<boolean>(true);
  const { search } = useLocation();
  const [conference, setConference] = useState<
    iWaitingRoomCardProps['conference']
  >({} as iWaitingRoomCardProps['conference']);
  const [orgInfo, setOrgInfo] = useState<GetPublicTopicById.Model['org']>();

  const { theme } = useContext(ThemeContext);

  const startCamera = () => {
    setTimeout(() => {
      const video: HTMLVideoElement | null = document.querySelector('#webcam');

      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: false, video: { facingMode: 'user' } })
          .then(function (stream) {
            window.localStream = stream;

            if (!video) return;

            video.srcObject = stream;

            video.setAttribute('autoplay', '');
            video.setAttribute('muted', '');
            video.setAttribute('playsinline', '');
          })
          .catch(function (error) {
            // this.setState({ camStatus: false});
          });
      }
    }, 300);
  };

  const stopCamera = () => {
    try {
      window.localStream.getTracks().forEach((track: any) => {
        track.stop();
      });
    } catch {
      AlertMessage({
        message: 'Não foi possível desativar a câmera.',
        type: 'warning',
      });
    }
  };

  const toggleWebcam = () => {
    if (isCamOn) {
      stopCamera();
    } else {
      startCamera();
    }

    setIsCamOn(prevState => !prevState);
  };

  const stopMic = () => {
    try {
      window.localStream.getTracks().forEach((track: any) => {
        track.stop();
      });
    } catch {
      console.error('Não foi possível desativar o microfone.');
    }
  };

  const toggleMic = () => {
    try {
      if (isMicOn) {
        stopMic();
      }
      setIsMicOn(prevState => !prevState);
    } catch {
      AlertMessage({
        message: 'Não foi possível desativar o microfone.',
        type: 'warning',
      });
    }
  };

  useEffect(() => {
    if (isCamOn) startCamera();
  }, [startCamera]);

  const requestId = window.location.pathname.split('/').pop();

  // TODO: Retornar o topic ID para retirar essas requests
  useEffect(() => {
    if (!requestId) return;
    makeRemoteListSacRequestById()
      .listById({
        request: requestId,
        token: new URLSearchParams(window.location.search).get('token') || '',
      })
      .then(res => {
        makeRemoteGetPublicTopicById()
          .getById({
            topic: res.topic?.id,
            token:
              new URLSearchParams(window.location.search).get('token') || '',
          })
          .then(response => {
            setOrgInfo(response.org);
          })
          .catch(() => {
            toast.error('Erro ao buscar dados do assunto');
          });
      })
      .catch(() => {
        toast.error('Erro ao carregar detalhes da solicitação');
      });
  }, [requestId]);

  return (
    <Container>
      <Header>
        <div>
          <Link to="/home">
            {theme.images.logo.small && (
              <img
                src={theme.images.logo.small}
                alt="Logo"
                style={{
                  maxWidth: '76px',
                  maxHeight: '20px',
                }}
              />
            )}
          </Link>
        </div>
        <div className="title">
          {`(${orgInfo?.shortname})  ${orgInfo?.name}`}
        </div>
      </Header>
      {true ? (
        <Body>
          <Left>
            <WaitingRoomContentStyle>
              <WaitingRoomContentHeader>
                <WaitingRoomContentTabs>
                  <div>Configurações</div>
                </WaitingRoomContentTabs>
              </WaitingRoomContentHeader>
              <WaitingRoomContentBody>
                {isCamOn ? (
                  <video autoPlay id="webcam">
                    <track kind="captions" />
                  </video>
                ) : (
                  <div />
                )}
              </WaitingRoomContentBody>
              <WaitingRoomContentFooter>
                <WaitingRoomContentFooterButtons>
                  <WaitingRoomContentFooterButton
                    isActive={isMicOn}
                    onClick={toggleMic}
                    type="button"
                  >
                    {isMicOn ? <IconMicrophoneOn /> : <IconMicrophoneOff />}
                  </WaitingRoomContentFooterButton>
                  <WaitingRoomContentFooterButton
                    isActive={isCamOn}
                    onClick={toggleWebcam}
                    type="button"
                  >
                    {isCamOn ? <IconCameraOn /> : <IconCameraOff />}
                  </WaitingRoomContentFooterButton>
                </WaitingRoomContentFooterButtons>
              </WaitingRoomContentFooter>
            </WaitingRoomContentStyle>
          </Left>
          <Right>
            <SacWaitingRoomInfoCard request={Number(requestId)} />
          </Right>
        </Body>
      ) : (
        <Finished>
          <FinishedContainer>
            <div className="icon">
              <IconCheck />
            </div>
            <div className="text">
              <strong>
                Agradecemos pelo seu contato! Seu atendimento foi concluído.
              </strong>
              <p>Caso precise de mais ajuda, estamos à disposição.</p>
            </div>
            <Button variant="primary" type="button" size="large">
              Enviar nova solicitação
            </Button>
          </FinishedContainer>
        </Finished>
      )}
    </Container>
  );
};

export default SacWaitingRoom;
