import { ThemeContext } from 'App';
import { iStore } from 'domain/interfaces/models';
import { makeReduxLogoutAuth } from 'main/factories/usecases/auth/LogoutAuthFactory';
import { makeRemoteGetUser } from 'main/factories/usecases/user/GetUserFactory';
import { History } from 'main/routes';
import {
  IconCalendar,
  IconCam,
  IconConfig,
  IconMonitoring,
  IconV4hHeader,
  Logout,
  Perfil,
} from 'presentation/base/icons';
import Popover from 'presentation/components/UI/popover/Popover';
import { AvatarImage } from 'presentation/components/avatar';
import { Icon } from 'presentation/components/icon';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getRole } from 'utils/getRole';
import { mapperRoles } from 'utils/mapperRoles';
import { Icon as IconDS } from '@wisecare-tech/design-system-web';
import Translator from '../i18n/Translator';
import {
  AdministrationOptions,
  AuditOption,
  Avatar,
  Container,
  Content,
  DashboardOption,
  Drop,
  DropInfo,
  Left,
  Options,
  OrganizationsOption,
  Right,
  UserOptions,
  UsersOption,
} from './styles/StyledHeader';

interface ownProps {
  title: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  value?: number;
  valueDescription?: string;
  haveUpdated?: boolean;
}

type Props = ownProps;

const Header: React.FC<Props> = ({
  title,
  icon,
  value,
  valueDescription,
  haveUpdated,
}): JSX.Element => {
  const url = window.location.pathname;
  const Icone = icon;
  const text = '';

  const [userName, setUserName] = useState('');
  const [userAvatar, setUserAvatar] = useState('');

  const { theme } = useContext(ThemeContext);

  const {
    user,
    access: { token },
  } = useSelector((store: iStore) => store.auth);

  const role = useMemo(
    () =>
      user?.administrator
        ? getRole(user.administrator, user.org.id, user.orgUnit.id)
        : 'STANDARD',
    [user?.administrator, user?.org.id, user?.orgUnit.id],
  );

  useEffect(() => {
    makeRemoteGetUser()
      .get({
        id: Number(user?.id),
      })
      .then(res => {
        setUserName(res.fullName);
        setUserAvatar(res.avatar);
      })
      .catch(() => {
        console.log('error');
      });
  }, [user?.id, haveUpdated, token]);

  const userOptions = (
    <UserOptions>
      <DropInfo>
        <span className="name">{`${userName}`}</span>
        <span className="role">{mapperRoles(role)}</span>
      </DropInfo>
      <button
        type="button"
        onClick={() => {
          if (user?.id) {
            History.getHistory().push(
              `/admin/users/${user?.id}?org=${user?.org?.id}&unit=${user?.orgUnit?.id}`,
              {
                from: url.replaceAll('/', ''),
              },
            );
          }
        }}
      >
        <Drop id="btn_login" data-testid="btn-login">
          <Perfil />
          <span>{Translator('Minha conta')}</span>
        </Drop>
      </button>
      <Drop
        id="btn_conferences"
        data-testid="btn-conferences"
        onClick={() => {
          History.getHistory().push(`/appointment/me`);
        }}
      >
        <IconCam id="cam_conference" />
        <span>{Translator('Minhas reuniões')}</span>
      </Drop>
      <Drop
        id="btn_logout"
        data-testid="btn-logout"
        onClick={() => {
          History.getHistory().push('/');
          makeReduxLogoutAuth().logout({});
        }}
      >
        <Logout />
        <span>{Translator('Sair')}</span>
      </Drop>
    </UserOptions>
  );

  const admOptions = (
    <div>
      <OrganizationsOption
        resourcers={['ORG', 'ORGUNIT']}
        actions={['READ_ANY', 'READ_ORG', 'READ_UNIT', 'READ_OWN']}
      >
        <Drop
          data-testid="btn-org"
          selected={url.includes('admin/orgs')}
          onClick={() => {
            if (!url.includes('admin/orgs'))
              History.getHistory().push('/admin/orgs');
          }}
        >
          {Translator('Organizações')}
        </Drop>
      </OrganizationsOption>
      <UsersOption
        resourcers={['USER']}
        actions={['READ_ANY', 'READ_ORG', 'READ_UNIT']}
      >
        <Drop
          data-testid="btn-users"
          selected={url.includes('admin/users')}
          onClick={() => {
            if (!url.includes('admin/users'))
              History.getHistory().push('/admin/users');
          }}
        >
          {Translator('Usuários')}
        </Drop>
      </UsersOption>
      <AuditOption
        resourcers={['ORG']}
        actions={['READ_AUDIT_ANY', 'READ_AUDIT_ORG', 'READ_AUDIT_OWN']}
      >
        <Drop
          data-testid="btn-audit"
          selected={url.includes('admin/audit')}
          onClick={() => {
            if (!url.includes('admin/audit'))
              History.getHistory().push('/admin/audit');
          }}
        >
          Auditoria
        </Drop>
      </AuditOption>
      {/* <div>
        <Drop>Configuração</Drop>
      </div> */}
    </div>
  );

  const analyticsOptions = (
    <div>
      <DashboardOption
        resourcers={['ORG']}
        actions={[
          'READ_DASHBOARD_ORG',
          'READ_DASHBOARD_ANY',
          'READ_DASHBOARD_OWN',
        ]}
      >
        <Drop
          data-testid="btn-dashboard-monitoring"
          selected={url.includes('dashboard/monitoring')}
          onClick={() => {
            if (!url.includes('dashboard/monitoring'))
              History.getHistory().push('/dashboard/monitoring');
          }}
        >
          {Translator('Painel de reuniões')}
        </Drop>
      </DashboardOption>

      <DashboardOption
        resourcers={['ORG']}
        actions={[
          'READ_DASHBOARD_ORG',
          'READ_DASHBOARD_ANY',
          'READ_DASHBOARD_OWN',
        ]}
      >
        <Drop
          data-testid="btn-dashboard-user"
          selected={url.includes('dashboard/user')}
          onClick={() => {
            if (!url.includes('dashboard/user'))
              History.getHistory().push('/dashboard/user');
          }}
        >
          {Translator('Classificação de usuários')}
        </Drop>
      </DashboardOption>

      <DashboardOption resourcers={['ORG']} actions={['READ_DASHBOARD_ANY']}>
        <Drop
          data-testid="btn-dashboard-org"
          selected={url.includes('dashboard/org')}
          onClick={() => {
            if (!url.includes('dashboard/org'))
              History.getHistory().push('/dashboard/org');
          }}
        >
          {Translator('Classificação de organizações')}
        </Drop>
      </DashboardOption>
    </div>
  );

  return (
    <Container>
      <Content>
        <Left>
          <Link to="/home">
            {theme.images.logo.small && (
              <img
                src={theme.images.logo.small}
                alt="Logo"
                style={{
                  maxWidth: '165px',
                  maxHeight: '64px',
                }}
              />
            )}
          </Link>
          <Link to="/home">
            <Options data-testid="appointment" selected={url.includes('home')}>
              <Icon src={IconCalendar} width="24px" height="26px" />
              <span>{Translator('Agenda')}</span>
            </Options>
          </Link>
          <AdministrationOptions
            resourcers={['ORG', 'ORGUNIT', 'USER']}
            actions={['READ_ANY', 'READ_ORG', 'READ_UNIT']}
          >
            <Popover content={admOptions} originX="center" originY="bottom">
              <Options
                data-testid="admin-options"
                selected={url.includes('admin')}
              >
                <Icon src={IconConfig} width="25px" height="25px" />
                <span>{Translator('Administração')}</span>
              </Options>
            </Popover>
          </AdministrationOptions>

          <DashboardOption
            resourcers={['ORG']}
            actions={[
              'READ_DASHBOARD_ORG',
              'READ_DASHBOARD_ANY',
              'READ_DASHBOARD_OWN',
            ]}
          >
            <Popover
              content={analyticsOptions}
              originX="center"
              originY="bottom"
            >
              <Options
                data-testid="admin-options"
                selected={url.includes('dashboard')}
              >
                <Icon src={IconMonitoring} width="25px" height="25px" />
                <span>{Translator('Estatísticas')}</span>
              </Options>
            </Popover>
          </DashboardOption>

          <Link to="/sac/list">
            <Options data-testid="sacList">
              <IconDS name="videocam" size="h5" />
              <span>Suporte</span>
            </Options>
          </Link>
        </Left>

        <Right>
          <Icon
            className="iconOrg"
            src={user?.org?.logo ?? IconV4hHeader}
            fallback={IconV4hHeader}
            width="128px"
            height="64px"
          />
          {/* <Icon src={NotificationIcon} width="21px" height="26px" /> */}
          <Popover
            content={userOptions}
            originX="right"
            originY="bottom"
            positionY="top"
            positionX="right"
          >
            <Avatar data-testid="avatar">
              <AvatarImage src={userAvatar} name={`${userName}`} size="small" />
            </Avatar>
          </Popover>
        </Right>
      </Content>
    </Container>
  );
};

export default Header;
