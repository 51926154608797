import { GetSacConfig as UsecaseRemoteGetSacConfig } from 'domain/usecases/sacConfig/remote';
import { makeApiUrl, makeHttpClient } from 'main/factories/http';

import { RemoteGetSacConfig } from 'data/repository/sacConfig';

/**
 * send request via API.
 */
export const makeRemoteGetSacConfig = (): UsecaseRemoteGetSacConfig =>
  new RemoteGetSacConfig(makeApiUrl('/sac/'), makeHttpClient());
