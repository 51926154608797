import { theme } from '@wisecare-tech/design-system-web';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  @media screen and (min-width: 1366px) {
    max-width: 886px;
  }
`;

export const SectionsContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  padding-inline: 1.5rem;
  padding-bottom: 1.5rem;

  background-color: ${theme.colors.white[3]};

  @media screen and (min-width: 1366px) {
    align-items: center;
  }
`;

export const SectionRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  @media screen and (min-width: 1366px) {
    & > * {
      max-width: 212px;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const SectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

export const ChipsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 1.5rem;
  padding: 1.5rem 1rem;
  background-color: ${theme.colors.white.DEFAULT};

  border: 1px solid ${theme.colors.white[6]};

  &:only-child {
    border-radius: 0.5rem;
  }

  &:not(:is(:last-child, :first-child)) {
    border-top: none;
  }

  &:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &:last-child {
    border-end-start-radius: 0.5rem;
    border-end-end-radius: 0.5rem;
    border-top: none;
  }
`;

export const PhoneNumbersContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.25rem;
`;

export const Footer = styled.div`
  display: flex;
  position: sticky;
  bottom: 0;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem 1.5rem;
  background-color: ${theme.colors.white.DEFAULT};
  border-top: 1px solid ${theme.colors.white[6]};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
`;

export const CustomScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;

  &:not(:last-child) {
    margin-right: 1.5rem;
  }
`;

export const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  padding-bottom: 1.5rem;

  @media screen and (min-width: 1366px) {
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;

    border-bottom: 1px solid ${theme.colors.white[6]};
    margin-bottom: 1.5rem;
    &:is(:last-child, :only-child, :only-of-type) {
      border-bottom: none;
      margin-bottom: 0;
    }

    & > text {
      flex: 1;
      max-width: 5rem;
    }
  }
`;
