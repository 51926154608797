import { array, InferType, mixed, number, object, string } from 'yup';
import { DaysAbbreviation } from '~/presentation/hooks/sac/types';

export const createTopicConfigSchema = object({
  title: string().required('O título é obrigatório'),
  org: number().required('A organização é obrigatória'),
  step: number().optional().default(1),
  contactPhones: array()
    .of(string().required('O telefone é obrigatório'))
    .min(1, 'Adicione ao menos um telefone'),
  contactEmail: string().required('O e-mail de suporte é obrigatório'),
  config: object({
    scheduleType: mixed<'FLEXIBLE' | 'FIXED'>().default('FIXED'),
  }).optional(),
  attendants: array()
    .of(number())
    .when('step', {
      is: 2,
      then: array().min(1, 'Adicione ao menos um atendente'),
      otherwise: array().min(0),
    }),
  schedule: array()
    .of(
      object({
        day: mixed<DaysAbbreviation>()
          .oneOf(['MO', 'TU', 'WE', 'TH', 'FR', 'ST', 'SU'])
          .required('Selecione ao menos um dia.'),
        hours: array()
          .of(
            object({
              start: string().required('O horário de início é obrigatório'),
              finish: string().required('O horário de término é obrigatório'),
            }),
          )
          .required('Adicione ao menos um horário')
          .min(1, 'Adicione ao menos um horário'),
      }),
    )
    .required('Adicione ao menos um dia')
    .min(1, 'Adicione ao menos um dia'),
});

export type CreateTopicConfigSchema = InferType<typeof createTopicConfigSchema>;
