/* eslint-disable react/jsx-one-expression-per-line */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Textarea } from '@wisecare-tech/design-system-web';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { iMessage, iStore } from 'domain/interfaces/models';
import { useSelector } from 'react-redux';
import { IconInfoBlue } from 'presentation/base/icons';
import { makeRemoteCloseSacRequest } from 'main/factories/usecases/sacRequest/CloseSacRequestFactory';
import { CloseSacRequest } from 'domain/usecases/sacRequest/remote';
import { toast } from 'react-toastify';
import {
  Body,
  Header,
  Container,
  Footer,
  ReportFinishReasonContainer,
  ReportFinishReasonContent,
  TextAreaDiv,
  TextArea,
} from './styles/StyledFinishAppointment';
import { Button } from '../../UI';
import { Outcome } from '../interface';

export interface propsModalFinishAppointmentSAC {
  message: iMessage;
}

type StatusOption = 'SOLVED' | 'UNSOLVED' | 'INTERRUPTED';

type OutcomeChecked = {
  outcome: {
    solved: boolean;
    unsolved: boolean;
    interrupted: boolean;
  };
};

const FinishAppointmentSAC: React.FC<propsModalFinishAppointmentSAC> = ({
  message,
}) => {
  const msgName = MessageOptions.finishAppointmentSAC;

  const { active, actionCancel, data, actionOk } = message;

  const [checked, setChecked] = useState<OutcomeChecked['outcome']>({
    interrupted: false,
    solved: true,
    unsolved: false,
  });
  const [reportReason, setReportReason] = useState('');

  const handleCheckValue = (check: OutcomeChecked['outcome']) => {
    if (check.solved) {
      return 'RESOLVED';
    }

    if (check.unsolved) {
      return 'UNRESOLVED';
    }
    return 'INTERRUPTED';
  };

  const handleSubmitNoteResponse = useCallback(() => {
    makeRemoteCloseSacRequest()
      .close({
        request: data.request,
        body: {
          outcome: handleCheckValue(checked),
          result: reportReason,
        },
      })
      .then(() => {
        actionOk?.();

        toast.success('Atendimento finalizado com sucesso');
      })
      .catch(() => {
        toast.error('Erro ao finalizar atendimento');
      });
  }, [data, checked, reportReason]);

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Header>
              <div>
                <IconInfoBlue />
              </div>
              <h3>Finalizar atendimento</h3>
              <p>
                Antes de encerrar, é necessário definir um status para esse
                atendimento:
              </p>
            </Header>

            <div className="optionForFinishing">
              <ReportFinishReasonContainer
                isActive={!checked}
                className="solved"
              >
                {checked.solved ? (
                  <ReportFinishReasonContent isActive={checked.solved}>
                    <div
                      style={{
                        padding: '8px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '4px',
                      }}
                    >
                      <input
                        type="radio"
                        checked={checked.solved}
                        onChange={e => {
                          if (e.target.checked) {
                            setChecked({
                              ...checked,
                              solved: e.target.checked,
                              interrupted: false,
                              unsolved: false,
                            });
                          }
                        }}
                      />
                      <strong>Resolvido</strong>
                      <span>(Atendimento finalizado com sucesso)</span>
                    </div>
                    <form style={{ padding: '8px 16px' }}>
                      <Textarea
                        placeholder="Digite aqui"
                        defaultValue={reportReason}
                        onChange={(e: any) => setReportReason(e.target.value)}
                      />
                    </form>
                  </ReportFinishReasonContent>
                ) : (
                  <div>
                    <div
                      style={{
                        padding: '8px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '4px',
                      }}
                    >
                      <input
                        type="radio"
                        checked={checked === null}
                        onChange={e => {
                          if (e.target.checked) {
                            setChecked({
                              ...checked,
                              solved: e.target.checked,
                              interrupted: false,
                              unsolved: false,
                            });
                          }
                        }}
                      />
                      <strong>Resolvido</strong>
                      <span style={{ color: '#656a6e' }}>
                        (Atendimento finalizado com sucesso)
                      </span>
                    </div>
                  </div>
                )}
              </ReportFinishReasonContainer>
              <ReportFinishReasonContainer
                isActive={!checked}
                className="unsolved"
              >
                {checked.unsolved ? (
                  <ReportFinishReasonContent isActive={checked.unsolved}>
                    <div
                      style={{
                        padding: '8px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '4px',
                      }}
                    >
                      <input
                        type="radio"
                        checked={checked.unsolved}
                        onChange={e => {
                          if (e.target.checked) {
                            setChecked({
                              ...checked,
                              unsolved: e.target.checked,
                              interrupted: false,
                              solved: false,
                            });
                          }
                        }}
                      />
                      <strong>Não resolvido</strong>
                      <span>(Atendimento sem resultado positivo)</span>
                    </div>
                    <form style={{ padding: '8px 16px' }}>
                      <Textarea
                        placeholder="Digite aqui"
                        defaultValue={reportReason}
                        onChange={(e: any) => setReportReason(e.target.value)}
                      />
                    </form>
                  </ReportFinishReasonContent>
                ) : (
                  <div>
                    <div
                      style={{
                        padding: '8px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '4px',
                      }}
                    >
                      <input
                        type="radio"
                        checked={checked === null}
                        onChange={e => {
                          if (e.target.checked) {
                            setChecked({
                              ...checked,
                              unsolved: e.target.checked,
                              interrupted: false,
                              solved: false,
                            });
                          }
                        }}
                      />
                      <strong>Não resolvido</strong>
                      <span style={{ color: '#656a6e' }}>
                        (Atendimento sem resultado positivo)
                      </span>
                    </div>
                  </div>
                )}
              </ReportFinishReasonContainer>
              <ReportFinishReasonContainer
                isActive={!checked}
                className="interrupted"
              >
                {checked.interrupted ? (
                  <ReportFinishReasonContent isActive={checked.interrupted}>
                    <div
                      style={{
                        padding: '8px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '4px',
                      }}
                    >
                      <input
                        type="radio"
                        checked={checked.interrupted}
                        onChange={e => {
                          if (e.target.checked) {
                            setChecked({
                              ...checked,
                              interrupted: e.target.checked,
                              unsolved: false,
                              solved: false,
                            });
                          }
                        }}
                      />
                      <strong>Interrompido</strong>
                      <span>(Perdeu conexão com o solicitante)</span>
                    </div>
                    <form style={{ padding: '8px 16px' }}>
                      <Textarea
                        placeholder="Digite aqui"
                        defaultValue={reportReason}
                        onChange={(e: any) => setReportReason(e.target.value)}
                      />
                    </form>
                  </ReportFinishReasonContent>
                ) : (
                  <div>
                    <div
                      style={{
                        padding: '8px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '4px',
                      }}
                    >
                      <input
                        type="radio"
                        checked={checked === null}
                        onChange={e => {
                          if (e.target.checked) {
                            setChecked({
                              ...checked,
                              interrupted: e.target.checked,
                              unsolved: false,
                              solved: false,
                            });
                          }
                        }}
                      />
                      <strong>Interrompido</strong>
                      <span style={{ color: '#656a6e' }}>
                        (Perdeu conexão com o solicitante)
                      </span>
                    </div>
                  </div>
                )}
              </ReportFinishReasonContainer>
              <Footer>
                <Button
                  type="button"
                  variant="secundary"
                  size="small"
                  onClick={actionCancel}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  size="small"
                  onClick={handleSubmitNoteResponse}
                >
                  Concluir
                </Button>
              </Footer>
            </div>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default FinishAppointmentSAC;
