import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';
import { GetTopics } from '~/domain/usecases/sacTopic/remote';
import { makeRemoteDeleteTopic } from '~/main/factories/usecases/sacTopic/DeleteTopic';
import { makeRemoteGetTopics } from '~/main/factories/usecases/sacTopic/GetTopics';
import { makeRemoteUpdateTopic } from '~/main/factories/usecases/sacTopic/UpdateTopic';

interface ListTopicsContextValue {
  topics: GetTopics.Model['records'];
  disabledTopics: GetTopics.Model['records'];
  overview: GetTopics.Model['overview'];
  isLoading?: boolean;
  handleUpdateTopic: (props: { id: number; active: boolean }) => void;
  handleDeleteTopic: (id: number) => void;
}

const ListTopics = createContext<ListTopicsContextValue | undefined>(undefined);

export const useListTopics = (): ListTopicsContextValue => {
  const context = useContext(ListTopics);
  if (!context) {
    throw new Error('useMyContext must be used within a MyContextProvider');
  }
  return context;
};

export const ListTopicsProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [topics, setTopics] = React.useState<GetTopics.Model>({
    metadata: {
      offset: 0,
      limit: 0,
      total: 0,
    },
    records: [],
    overview: {
      active: 0,
      inactive: 0,
    },
  });

  const [disabledTopics, setDisabledTopics] = React.useState<GetTopics.Model>({
    metadata: {
      offset: 0,
      limit: 0,
      total: 0,
    },
    records: [],
    overview: {
      active: 0,
      inactive: 0,
    },
  });

  const getTopics = useCallback(async () => {
    setIsLoading(true);
    const getTopicsById = makeRemoteGetTopics();

    try {
      const response = await getTopicsById.list({});
      setTopics(response);
    } catch (error) {
      toast.error('Erro ao buscar assuntos');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getDisabledTopics = useCallback(async () => {
    setIsLoading(true);
    const getTopicsById = makeRemoteGetTopics();

    try {
      const response = await getTopicsById.list({
        filters: {
          active: false,
        },
      });
      setDisabledTopics(response);
    } catch (error) {
      toast.error('Erro ao buscar assuntos');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleDeleteTopic = useCallback(
    async (id: number) => {
      const deleteTopic = makeRemoteDeleteTopic();

      try {
        await deleteTopic.delete({
          id,
        });
      } catch (error) {
        console.error(error);
      } finally {
        await Promise.all([getTopics(), getDisabledTopics()]);
      }
    },
    [getDisabledTopics, getTopics],
  );

  const handleUpdateTopic = useCallback(
    async (props: { id: number; active: boolean }) => {
      const updateTopic = makeRemoteUpdateTopic();

      const { id, active } = props;

      try {
        await updateTopic.update({
          path: {
            topic: id,
          },
          body: {
            active,
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        await Promise.all([getTopics(), getDisabledTopics()]);
      }
    },
    [getDisabledTopics, getTopics],
  );

  useEffect(() => {
    getTopics();
  }, [getTopics]);

  useEffect(() => {
    getDisabledTopics();
  }, [getDisabledTopics]);

  const value: ListTopicsContextValue = {
    isLoading,
    disabledTopics: disabledTopics.records,
    topics: topics.records,
    overview: topics.overview,
    handleUpdateTopic,
    handleDeleteTopic,
  };

  return <ListTopics.Provider value={value}>{children}</ListTopics.Provider>;
};
