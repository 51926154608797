import { Button, IconButton } from '@wisecare-tech/design-system-web';
import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import React from 'react';
import { daysMap } from '~/presentation/hooks/sac/createTopicConfig';
import { DaysAbbreviation } from '~/presentation/hooks/sac/types';
import { iModalSupportInformation } from '~/presentation/pages/Sac/types';
import { closeModal } from '~/utils/closeModal';
import {
  Container,
  ContainerModal,
  Footer,
  Header,
  Information,
  InformationItem,
  InformationItemText,
  InformationItemTextContainer,
  InformationItemTitle,
  Schedule,
  ScheduleItem,
  Title,
} from './styles/StyledSupportInformation';

export interface propsSupportInformation {
  message: iMessage;
}

export const SupportInformation: React.FC<propsSupportInformation> = ({
  message,
}) => {
  const msgName = MessageOptions.supportInformation;

  const { active, actionOk, data } = message;

  const values = data as iModalSupportInformation;
  const valuesSortted = values?.schedule?.sort(
    (a, b) => daysMap[a.day].value - daysMap[b.day].value,
  );

  const allContactPhonesAreDifferent = (phones: string[]) => {
    return phones.length === new Set(phones).size;
  };

  return (
    <>
      {msgName === active ? (
        <ContainerModal>
          <Container>
            <Header>
              <Title>
                {values?.subject
                  ? 'Informações da linha de atendimento'
                  : 'Informações do suporte'}
              </Title>
              <IconButton
                variant="primary"
                icon="close"
                size="sm"
                onClick={closeModal}
              />
            </Header>
            <Information>
              {values?.subject && (
                <InformationItem>
                  <InformationItemTitle>Assunto:</InformationItemTitle>
                  <InformationItemText>{values?.subject}</InformationItemText>
                </InformationItem>
              )}
              <InformationItem>
                <InformationItemTitle>E-mail:</InformationItemTitle>
                <InformationItemText>{values?.email}</InformationItemText>
              </InformationItem>
              <InformationItem>
                <InformationItemTitle>Telefone:</InformationItemTitle>
                <InformationItemTextContainer>
                  {values?.phones &&
                  values?.phones.length > 1 &&
                  allContactPhonesAreDifferent(values?.phones) ? (
                    values?.phones.map((phone: string) => (
                      <InformationItemText>{phone}</InformationItemText>
                    ))
                  ) : (
                    <InformationItemText>
                      {values?.phones?.[0]}
                    </InformationItemText>
                  )}
                </InformationItemTextContainer>
              </InformationItem>
              <InformationItem>
                <InformationItemTitle>
                  Horário de funcionamento:
                </InformationItemTitle>
              </InformationItem>
            </Information>
            <Schedule>
              {valuesSortted?.map(scheduleDay => (
                <ScheduleItem key={scheduleDay?.day}>
                  <InformationItemTitle>
                    {[daysMap[scheduleDay?.day as DaysAbbreviation].fullLabel]}
                  </InformationItemTitle>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      flexWrap: 'wrap',
                    }}
                  >
                    {scheduleDay.hours?.map(hour => (
                      <InformationItemText divider=", ">
                        {`${hour?.start.slice(0, 5)} - 
                        ${hour?.finish.slice(0, 5)}`}
                      </InformationItemText>
                    ))}
                  </div>
                </ScheduleItem>
              ))}
            </Schedule>
            <Footer>
              <Button
                variant="primary"
                onClick={() => {
                  actionOk?.();
                  closeModal();
                }}
              >
                {values.subject ? 'Editar assunto' : 'Editar configurações'}
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      ) : null}
    </>
  );
};
